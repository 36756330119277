import { LoginContext } from '@/context/login'
import { useContext, useState } from 'react'
import Input from '@/components/ui/Input'
import Account from '@/components/ui/Account'
import Button from '@/components/ui/Button'
import { useForm } from 'react-hook-form'
import { faEnvelope, faWallet } from '@arcane-ui/pro-duotone-svg-icons'
import { faFingerprint as fasFingerprint } from '@arcane-ui/pro-solid-svg-icons'
import { faLandmark, faUser } from '@arcane-ui/pro-solid-svg-icons'
import AuthService from '@/services/auth'
import AuthCode from '@/components/auth/AuthCode'
import Alert from '@/components/ui/Alert'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

export default function InitLogin() {
	const [, loginDispatch] = useContext(LoginContext)
	const [loading, setLoading] = useState(false)
	const [accountType, setAccountType] = useState('INDIVIDUAL')
	const [error, setError] = useState(null)
	const { executeRecaptcha } = useGoogleReCaptcha()
	const { register, handleSubmit, errors } = useForm()

	const onSubmit = async (data) => {
		setError(null)
		setLoading(true)

		const recaptchaToken = await executeRecaptcha('login_page')

		const config = {
			headers: {
				Recaptcha: recaptchaToken,
			},
		}

		const body = {
			email: data.email,
			type: accountType,
		}

		console.log(body)

		await AuthService.Signup(body, config)
			.then(() => {
				loginDispatch({
					type: 'SET_LOGIN',
					payload: {
						auth: {
							email: body.email,
							code: '',
							account_type: accountType,
						},
						currentComponent: <AuthCode></AuthCode>,
					},
				})
			})
			.catch((err) => {
				setLoading(false)
				setError(err.message)
			})
		return () => {}
	}

	const formSchema = {
		email: {
			required: 'Email required',
			pattern: {
				value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
				message: 'Invalid email',
			},
		},
	}

	return (
		<>
			{error ? <Alert type='error' title={error}></Alert> : null}
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="flex justify-between mb-4">
					<div className="w-1/2 pr-2">
						<Account
							full
							value={accountType}
							account='INDIVIDUAL'
							icon={faUser}
							label='Individual'
							onClick={() => setAccountType('INDIVIDUAL')}
						/>
						</div>
						<div className="w-1/2 pl-2">
						<Account
							full
							onClick={() => setAccountType('CORPORATE')}
							icon={faLandmark}
							account='CORPORATE'
							value={accountType}
							label='Corporate'
						/>
						</div>
				</div>
				<div className='mb-5'>
					<Input
						icon={faEnvelope}
						label='Email'
						referrer={register(formSchema.email)}
						placeholder='john.doe@synaps.io'
						name='email'
						error={errors.email}
						message={errors.email?.message}
					/>
				</div>
				<div>
					<Button
						type='submit'
						full
						loading={loading}
						label='Sign Up'
					/>
				</div>
			</form>
		</>
	)
}
